<template>
  <button
    v-bind="$attrs"
    ref="refButton"
    :class="mainButtonClasses"
    :type="type"
    :data-inverted="undefinedIfNotTrue(inverted)"
    :aria-pressed="pressed"
    :tabindex="tabindex"
    :data-disabled="undefinedIfNotTrue(disabled)"
    :disabled="undefinedIfNotTrue(disabled || pending)"
    @click="togglePressed"
  >
    <span
      v-if="notification"
      class="absolute -right-2 -top-1 data-[button-style$=notification]:right-1 data-[button-style$=notification]:top-1 rounded-full bg-supporting-12 font-extrabold text-base-2 outline outline-2 -outline-offset-1 outline-supporting-16 w-5 h-5 text-sm data-[button-style$=compact]:w-4 data-[button-style$=compact]:h-4 data-[button-style$=compact]:text-xs data-[button-style$=narrow]:w-4 data-[button-style$=narrow]:h-4 data-[button-style$=narrow]:text-xs flex items-center justify-center contrast:hover:border-c-primary-0"
      :data-button-style="buttonStyle"
    >
      !
    </span>
    <slot
      v-else-if="buttonStyle !== 'button-notification' && !pending"
      name="icon"
    ></slot>
    <span
      v-if="buttonStyle === 'button-notification'"
      class="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-primary-8 text-base group-hover/button:bg-primary-7 group-aria-pressed/button:bg-primary-1 group-aria-pressed/button:border-0 contrast:group-aria-pressed/button:border contrast:group-aria-pressed/button:border-base-2 border border-primary-7 contrast:border-base-1 group-hover/button:border-primary-6 contrast:bg-c-secondary-0 contrast:group-hover/button:bg-c-primary-0 contrast:group-hover/button:text-base-2"
    >
      <slot v-if="!pending" name="icon"></slot>
      <LoadingIndicatorSvg v-else-if="$slots.icon" class="h-5 w-5" />
    </span>
    <LoadingIndicatorSvg
      v-if="
        ($slots.default || $slots.icon) &&
        pending &&
        buttonStyle !== 'button-notification'
      "
      class="h-6 w-6 data-[button-style$=narrow]:w-5 data-[button-style$=narrow]:h-5 data-[button-style$=compact]:w-4 data-[button-style$=compact]:h-4"
      :data-button-style="buttonStyle"
    />
    <slot v-else-if="$slots.default"></slot>
    <InfoTooltip
      v-if="$slots.tooltip && refButton"
      :id="`${id}_tooltip`"
      :target-element="refButton"
    >
      <slot name="tooltip"></slot>
    </InfoTooltip>
  </button>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { ref } from "vue";
import { undefinedIfNotTrue } from "../composables/attributes";
import type { ButtonStyle } from "../types/buttonStyle";
import type { PlacmentTooltip } from "../types/placmentTooltip";
import InfoTooltip from "./InfoTooltip.vue";
import LoadingIndicatorSvg from "./LoadingIndicatorSvg.vue";

interface Props {
  id: string;
  inverted?: boolean;
  buttonStyle?: ButtonStyle;
  type?: "button" | "reset" | "submit";
  toggle?: boolean;
  modelValue?: boolean;
  tabindex?: number;
  disabled?: boolean;
  placementTooltip?: PlacmentTooltip;
  notification?: boolean;
  pending?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  inverted: true,
  compact: false,
  type: "button",
  buttonStyle: "panel-button-normal",
  tabindex: 0,
  placementTooltip: "top",
  notification: false,
});

const pressed = computed({
  get() {
    if (!props.toggle) {
      return undefined;
    }
    return props.modelValue === true;
  },
  set(newValue) {
    if (props.toggle) {
      emit("update:modelValue", newValue === true);
    }
  },
});

onMounted(() => {
  if (
    props.toggle &&
    (props.modelValue === undefined || props.modelValue === null)
  ) {
    pressed.value = false;
  }
});

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean | undefined): void;
}>();

const refButton = ref<HTMLElement>();

function togglePressed() {
  if (props.toggle) {
    pressed.value = !pressed.value;
  }
}

function focus() {
  if (refButton.value) {
    refButton.value.focus();
  }
}

const mainButtonClasses = computed(() => {
  if (props.buttonStyle === "button-link") {
    return "group/button button-link";
  } else if (props.buttonStyle) {
    return `group/button button-base ${props.buttonStyle}`;
  }
  return "group/button button-base";
});

defineExpose({ focus, rootNode: refButton });
</script>
