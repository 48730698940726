export function useTheme() {
  const fontSizeCookie = useCookie<number | null>("fontSize");
  const contrastCookie = useCookie<number | null>("contrastTheme");

  const fontSizeClass = computed(() => {
    if (fontSizeCookie.value === 1) {
      return "font-size-1";
    } else if (fontSizeCookie.value === 2) {
      return "font-size-2";
    } else if (fontSizeCookie.value === 3) {
      return "font-size-3";
    }
    return undefined;
  });

  const contrastClass = computed(() => {
    if (contrastCookie.value === 1) {
      return "contrast-1";
    } else if (contrastCookie.value === 2) {
      return "contrast-2";
    } else if (contrastCookie.value === 3) {
      return "contrast-3";
    }
    return undefined;
  });

  const fontSize = computed(() => {
    return fontSizeCookie.value ?? 0;
  });

  const contrast = computed(() => {
    return contrastCookie.value ?? 0;
  });

  const themeClasses = computed(() => {
    let classes: string | undefined = "";
    if (fontSizeClass.value) {
      classes += fontSizeClass.value;
    }
    if (contrastClass.value) {
      if (classes !== "") {
        classes += " ";
      }
      classes += contrastClass.value;
    }
    if (classes === "") {
      classes = undefined;
    }

    return classes;
  });

  function setContrast(contrastThemeNumber: number | null) {
    contrastCookie.value = contrastThemeNumber;
    setTheme();
  }

  function setFontSize(fontSizeNumber: number) {
    if (fontSizeNumber === 0) {
      fontSizeCookie.value = null;
    }
    fontSizeCookie.value = fontSizeNumber;
    setTheme();
  }

  function setTheme() {
    document.documentElement.className = themeClasses.value ?? "";
  }

  callOnce(() => {
    useHead({
      htmlAttrs: {
        class: themeClasses.value ?? "",
      },
    });
  });

  return {
    themeClasses,
    setFontSize,
    setContrast,
    fontSizeClass,
    contrastClass,
    fontSize,
    contrast,
  };
}
